import "intersection-observer";
import swiper from "./swiper";
import lazyload from "./dal-lazyload";
import headerMenuTabs from "./header-menu-tabs";
import navigation from "./navigation";
import accordion from "./accordion";
import form from "./form";
import tabs from "./tabs";
import imageTabs from "./image-tabs";
import logoRows from "./logo-rows";
import liveticker from "./liveticker";
import cookieBanner from "./cookie-banner";
import "lazysizes";
import "lazysizes/plugins/blur-up/ls.blur-up";

// eslint-disable-next-line no-undef
/*
require("./autoload"); //enforce to copy all images of src into web folder
*/

export default {
    init() {
        headerMenuTabs.init();
        navigation.init();
        lazyload.init();
        accordion.init();
        logoRows.init();
        liveticker.init();
        cookieBanner.init();

        let allTabs = document.querySelectorAll(".pages-with-tabs");
        if (allTabs) {
            let allTabsSwiper = document.querySelectorAll(".tabs-container");
            if (allTabsSwiper.length) {
                allTabsSwiper.forEach(function (swiperEle) {
                    //Set swiper element and swiper options from data-attribute
                    tabs.init(swiperEle, JSON.parse(swiperEle.dataset.swiperOptions));
                })
            }
        }

        //Init all swiper if there are some
        let allImageTabsSwiper = document.querySelectorAll(".image-tabs-container");
        if (allImageTabsSwiper.length) {
            allImageTabsSwiper.forEach(function (swiperEle) {
                //Set swiper element and swiper options from data-attribute
                imageTabs.init(swiperEle, JSON.parse(swiperEle.dataset.swiperOptions));
            })
        }

        let allForms = document.querySelectorAll(".fui-form");
        if (allForms) {
            form.init();
        }

        let allSwiper = document.querySelectorAll(".swiper-container");
        if (allSwiper) {
            swiper.init();
        }

        const observer = new MutationObserver(() => {
            const widgetHost = document.querySelector("life-inside-widget-shadow-root");
            const shadowRoot = widgetHost?.shadowRoot;
            const container = shadowRoot?.querySelector("#li-widget-container");
          
            if (container) {
              container.style.zIndex = "2147483645";
              observer.disconnect();
            }
          });
          
          observer.observe(document.body, { childList: true, subtree: true });
    },
};